import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Box, dialogClasses, IconButton } from '@mui/material';
import { MultilineText } from 'modules/picasso-ui/present/MultilineText';
import { useTranslation } from 'next-i18next';
import { isString } from 'utils/stringUtils';
import { ButtonBar } from 'modules/picasso-ui/interact/ButtonBar';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { orElse } from 'utils/objectUtils';
import CloseIcon from '@mui/icons-material/Close';

const ConfirmationDialog = ({ open, title, message, onConfirm, onDismiss, 
  confirmButtonText, 
  cancelButtonText,
  confirmDisabled, children, maxWidth, fullWidth, 
  actionNotAdvised, 
  actionNotAdvisedSmooth,
  dontWrapInContentText,
  fullScreen, 
  titleTextAlign, 
  showCancelButton, 
  showTitleCloseButton,
  showWarningIcon, container,
  style,
  BackdropProps,
  sx,
  positionPaddingTop
  }) => {

  const { t } = useTranslation('common')

  maxWidth = maxWidth || 'xs'

  let confirmButtonTextEffective = confirmButtonText || t('action.confirm');

  const handleDismissClick = (e) => {
    if (onDismiss) {
      onDismiss(e, 'cancelClicked');
    }
  }

  const dialogTitleStyle = {
    textAlign: titleTextAlign || 'left', 
    fontSize: '20px',

    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    minWidth: '0',
    maxWidth: '85%',
  }

  const confirmBtnProps = {}

  if (actionNotAdvised) {
    confirmBtnProps.color = 'inherit'
    confirmBtnProps.variant = 'contained'
    confirmBtnProps.style={background: '#f25e5e', color: '#fff'} 
    showWarningIcon = orElse(showWarningIcon, true)
  } 
  else if(actionNotAdvisedSmooth) {
    confirmBtnProps.variant = 'text'
  }
  else {
    confirmBtnProps.color = 'primary'
    confirmBtnProps.variant = 'contained'
  }

  cancelButtonText = cancelButtonText || t('action.cancel')

  const cancelBtnContainerProps = {}
  if (actionNotAdvised) {
    cancelBtnContainerProps.justifyContent = 'flex-end'
  } 
  else if(actionNotAdvisedSmooth) {
    cancelBtnContainerProps.justifyContent = 'space-between'
  }
  else {
    cancelBtnContainerProps.justifyContent = 'flex-end'
  }

  const cancelBtnProps = {
    variant: 'contained',
    color: 'greyLight',
  }
  if (actionNotAdvised) {
    //cancelBtnProps.color = 'text'
  }
  else if(actionNotAdvisedSmooth) {
    cancelBtnProps.variant = 'contained'
    cancelBtnProps.color = 'primary'
  }
  else {
    //noop
  }

  const renderTitleCloseButton = (margin) => {

    margin = margin || '-5px'

    return (
      <Box display="flex" alignItems="center" position="absolute" right={margin} top={margin}>
        <IconButton onClick={handleDismissClick} size="small">
          <CloseIcon sx={{color: 'rgb(166 166 166 / 54%)'}} />
        </IconButton>
      </Box>
    )
  }

  const isMessageString = isString(message)

  const contentEffective = (dontWrapInContentText === true || isMessageString === false) ? 
      <>
        {message &&  (isMessageString ? <MultilineText>{message}</MultilineText>:message)}
        {children}
      </>
      :
      <DialogContentText>
       {message && (isMessageString ? <MultilineText>{message}</MultilineText>:message)}
      {children}
    </DialogContentText>


  const renderConfirmBtn = () =>
    <Button {...confirmBtnProps} onClick={onConfirm} disabled={confirmDisabled}>
      {confirmButtonTextEffective}
    </Button>

  const renderCancelBtn = () => <Box flexGrow={actionNotAdvisedSmooth ? null:1} {...cancelBtnContainerProps} display="flex">
    <Button {...cancelBtnProps} onClick={handleDismissClick}>{cancelButtonText}</Button>
  </Box>

  const dialogStyle = [sx]
  if (positionPaddingTop) {
    dialogStyle.push({
      [`& .${dialogClasses.scrollPaper}`]: {
        alignItems: 'baseline',
        paddingTop: positionPaddingTop,
      }
    })
  }

  return (
    <Dialog open={open} 
      onClose={onDismiss} 
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      PaperProps={{
        style: { borderRadius: 12 }
      }}
      container={container}
      style={style}
      BackdropProps={BackdropProps}
      sx={dialogStyle}
      >
      <Box display={showWarningIcon ? 'flex' : null} pt={showWarningIcon ? 1:null} position="relative">
        {showWarningIcon && 
              <div style={{padding: '18px 0px 0px 24px', marginRight: '-16px'}}>
                <ErrorOutlineIcon color="warning" sx={{fontSize: '1.9rem'}}/>
              </div>
        }
        <div style={showWarningIcon ? {paddingTop: '1px'} : null}>
          {title && <DialogTitle style={dialogTitleStyle}>
              {title}
              {(showTitleCloseButton) && renderTitleCloseButton('14px')}
            </DialogTitle>}
          <DialogContent>
            <Box sx={showWarningIcon? {paddingRight: '25px', paddingBottom: '10px'}: null} color="text.secondary">
              {contentEffective}
            </Box>
          </DialogContent>
          <DialogActions style={{justifyContent: actionNotAdvisedSmooth? "space-between":null, padding: '16px 16px 16px 16px'}}>
                {actionNotAdvisedSmooth === true ? 
                  <ButtonBar spacing={2}>
                    {renderConfirmBtn()}
                    {showCancelButton !== false && renderCancelBtn(2)}
                  </ButtonBar>
                  :
                  <ButtonBar spacing={2}>
                    {showCancelButton !== false && renderCancelBtn(2)}
                    {renderConfirmBtn()}
                  </ButtonBar>
                }
          </DialogActions>
        </div>
      </Box>
    </Dialog>
  );
};

const ConfirmationDialogContext = React.createContext({});

const ConfirmationDialogProvider = ({ children }) => {

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogConfig, setDialogConfig] = React.useState({});
  const [confirmDisabled, setConfirmDisabled] = React.useState(null);

  const openDialog = ({ title, titleTextAlign, message, confirmButtonText, cancelButtonText, actionCallback, confirmDisabledDefault, actionNotAdvised, actionNotAdvisedSmooth, maxWidth, fullWidth, showCancelButton }) => {
    setDialogOpen(true);
    setDialogConfig({ title, titleTextAlign, message, confirmButtonText, cancelButtonText, actionCallback, confirmDisabledDefault, actionNotAdvised, actionNotAdvisedSmooth, maxWidth, fullWidth, showCancelButton });
  };

  const resetDialog = () => {
    setDialogOpen(false);
    //setDialogConfig({}); when this line is enabled, it causes a 'lag' (empty dialog visible for a few moments)
    setConfirmDisabled(null);
  };

  const onConfirm = () => {
    resetDialog();
    if (dialogConfig.actionCallback) {
      dialogConfig.actionCallback(true);
    }
  };

  const onDismiss = () => {
    resetDialog();
    if (dialogConfig.actionCallback) {
      dialogConfig.actionCallback(false);
    }
  };

  const confirmDisabledEffective = () => {
    if (confirmDisabled === null) {
      if (dialogConfig?.confirmDisabledDefault === undefined) {
        return false;
      } else {
        return dialogConfig?.confirmDisabledDefault;
      }
    }
    return confirmDisabled;
  };

  return (
    <ConfirmationDialogContext.Provider value={{ openDialog, setConfirmDisabled }}>
      <ConfirmationDialog
        open={dialogOpen}
        title={dialogConfig?.title}
        titleTextAlign={dialogConfig?.titleTextAlign}
        message={dialogConfig?.message}
        confirmButtonText={dialogConfig?.confirmButtonText}
        cancelButtonText={dialogConfig?.cancelButtonText}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
        confirmDisabled={confirmDisabledEffective()}
        actionNotAdvised={dialogConfig?.actionNotAdvised}
        actionNotAdvisedSmooth={dialogConfig?.actionNotAdvisedSmooth}
        maxWidth={dialogConfig?.maxWidth}
        fullWidth={dialogConfig?.fullWidth}
        showCancelButton={dialogConfig?.showCancelButton}
      />
      {children}
    </ConfirmationDialogContext.Provider>
  );
};

const useConfirmationDialog = () => {
  const { openDialog, setConfirmDisabled, setPayload } = React.useContext(ConfirmationDialogContext);

  const getConfirmation = ({ ...options }) => {
    return new Promise((res) => {
      openDialog({ actionCallback: res, ...options });
    });
  };

  return { getConfirmation, setConfirmDisabled, setPayload };
};

export default ConfirmationDialog;
export { ConfirmationDialogProvider, useConfirmationDialog };
